<template>
  <div class="d-flex " :style="
                      'display:flex;justify-content:' + pro.style.position + ';'
                    ">
                    <div class="w-100" :style="getMarginCss(pro.style,isDesktopView)">
    <div class="" :style="timerLabelStyle(pro.style.label)" v-if="pro.label.status"  >{{pro.label.text}}</div>
                    <button :style="getTimerButtonCss(pro.style,isDesktopView)" >
                      
                      <span
                        :style="{
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        }"
                      >
                      <div class="" v-if="(isEditor&&pro.actionText.preview&&this.pro.timerAction=='text')|| counterCondition">{{pro.actionText.text}}</div>
                      <VueCountdown :time="timeInSec" v-else ref="counter" :auto-start="false" @end="endCountDown">
                        <template slot-scope="props">
                            <div class="d-flex align-items-center mr-1">
                            <span v-show="pro.counterSetting.initial.visible" v-html="pro.counterSetting.initial.text">
                            </span>
                            <!-- Days -->
                            <span class="mx-1" v-show="false" v-html="getCounterValue('days',props.days)">
                            </span>
                            <!-- Hours -->
                            <span class="mx-1"  v-show="pro.counterSetting.hours.visible" v-html="getCounterValue('hours',props.hours)">
                            </span>
                            <!-- Minutes -->
                            <span class="mx-1"  v-show="pro.counterSetting.minutes.visible" v-html="getCounterValue('minutes',props.minutes)">
                            </span>
                            <!-- Seconds -->
                            <span class="mx-1"  v-show="pro.counterSetting.seconds.visible"  v-html="getCounterValue('seconds',props.seconds)">
                            </span>
                        </div>
                        </template>
                    </VueCountdown>
                     
                      </span>
                      {{ pro.style.alignItems }}
                    </button>
                   </div>
  </div>
</template>
<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import cssFunctionMixin from "../../customize/mixin/cssFunction";
export default {
name:'timer',
mixins:[cssFunctionMixin],
props:['pro','isDesktopView',"isEditor"],
components:{
    VueCountdown
},
data(){
    return{
        counterCondition:false,
    }
},
computed:{
 timeInSec(){
    if(this.pro.timerValue)
    return parseInt(this.pro.timerValue)*1000;
    return 0;
 }
},

mounted(){
    if(!this.isEditor)
    this.$refs.counter.start();
},
methods:{
    formatTime(time) {
    return time < 10 ? `0${time}` : time;
  },
  getCounterValue(index,counterValue)
  {
    if(this.pro.counterSetting[index].textPosition=='first') 
        return this.pro.counterSetting[index].text+' '+ this.formatTime(counterValue)
    else
        return  this.formatTime(counterValue) +' '+this.pro.counterSetting[index].text            
                              
  },
  endCountDown(){
    if(this.pro.timerAction=='text')
    this.counterCondition=true;
    else
    this.$emit('endCounter',{action:this.pro.timerAction,'url':this.pro.timerActionLink});
  },
    timerLabelStyle(label){
    return {
            backgroundColor : label.backgroundColor+Math.floor((label.backgroundOpacity/100) * 255).toString(16),
            color: label.color+Math.floor((label.textOpacity/100)*255).toString(16),
            fontWeight:label.fontWeight,
            fontFamily:label.fontFamily,
            textAlign:label.textAlign,
            fontStyle: label.fontStyle,
            textDecoration: label.textDecoration,
            fontSize:label.fontSize+'px',
            // marginTop:this.pro.style.marginTop+'px',
            //  marginBottom:this.pro.style.marginBottom+'px',
            //   marginLeft:this.pro.style.marginLeft+'px',
            //    marginRight:this.pro.style.marginRight+'px'
        } 
    },
}
}
</script>