<script>
import centralEventBus from "../views/customize/component/EditorComponents/centralEventBus";
export default {
    props:['currElementIndex','deleteCondition','currElementType'],
  data() {
    return {
       
    };


    //   this.contentLocal = this.content;
  },
  methods: {
    deleteElement(){
        let index =this.currElementIndex
        if(index > -1){
            centralEventBus.$emit("deleteBlock", index);
        }
    },
    duplicateElement(){
        let index =this.currElementIndex
        if(index > -1){
            centralEventBus.$emit("handleDuplicate", index);
        }
    },
    shiftElement(direction){
        let index =this.currElementIndex
        if(index > -1){
            let obj = {
                direction:direction,
                index:index
            }
            centralEventBus.$emit("shiftEditorElement", obj);
        }
    }
    
  },
  computed: {
   allowedElementsDuplicates(){
    return ['text','html']
   },
   isElementDuplicatable(){
    return this.allowedElementsDuplicates.includes(this.currElementType)
   }
  },
};
</script>

<template>


<div class="actionWrapper">
    <div class="d-flex align-items-center">

        <button class="btn px-1" @click.stop="shiftElement('up')">
            <b-icon icon="arrow-up"></b-icon>
        </button>
        <button class="btn px-1"  @click.stop="shiftElement('down')">
            <b-icon icon="arrow-down"></b-icon>
        </button>
        <button class="btn px-1" v-if="isElementDuplicatable" @click.stop="duplicateElement()">
            <b-icon icon="files"></b-icon>
        </button>
        <button class="btn px-1"  v-if="deleteCondition" @click.stop="deleteElement()">
            <b-icon icon="trash" style="font-size:14px;"></b-icon>
        </button>
    </div>
</div>

</template>

<style scoped>
.actionWrapper{
    position:absolute;
    bottom:-32px;
    right:0;
    z-index:1000;
    background: #4d1b7e;
}
.actionWrapper button{
    color:#fff;
    display:flex;
    align-items:center;
    justify-content:center;
   
}

.actionWrapper .btn svg{
    font-size:18px;
}

</style>

