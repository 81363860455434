<template>
   <div class=""  :style="getMinHeight(pro.style)" v-html="pro.content">            
  </div>
</template>

<script>

import cssFunctionMixin from "../customize/mixin/cssFunction";
export default {
 mixins: [cssFunctionMixin],
 props:['pro','isDesktopView'],
 methods:{
    getMinHeight(property){
        return `min-height:${property.height}px`
    }
 }

}
</script>

<style scoped>

</style>